function initScrollUp() {
	const btnUp = document.querySelector(".btn-up");
	//const btnViber = document.querySelector(".btn-viber");

	btnUp.addEventListener("click", () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	});

	window.addEventListener('scroll', function () {

		if (pageYOffset > window.innerHeight / 2) {
			btnUp.classList.add('show');
			//btnViber.classList.add('show');
		} else {
			btnUp.classList.remove('show');
			//btnViber.classList.remove('show');
		}
	});
}

initScrollUp();

