document.addEventListener('DOMContentLoaded', () => {
    const cartRows = document.querySelectorAll('.cart-table-row');
    let isModalShow = false;

    cartRows.forEach(cartRow => {
        setupModalToggle(cartRow);
        setupCartCounter(cartRow);
        setupCartTypeSelect(cartRow);

        if (!isModalShow) {
            isModalShow = checkForBagUnit(cartRow, isModalShow);
        }
    });

    toggleNewAddressFields();
    toggleDeliveryMethod();
});

function setupModalToggle(cartRow) {
    const cartRowId = cartRow.querySelector('.cart-table-row-id');
    const cartRowPlus = cartRow.querySelector('.cart-table-row-plus');
    const cartModal = cartRow.querySelector('.cart-modal');
    const modalInner = cartModal?.querySelector(".cart-modal-inner");
    const modalClose = cartModal?.querySelector(".cart-modal-close");

    cartRowId && cartRowId.addEventListener('click', () => {
        fadeIn(cartModal);
        disableScroll();
    });

    cartRowPlus && cartRowPlus.addEventListener('click', () => {
        fadeIn(cartModal);
        disableScroll();
    });

    modalClose && modalClose.addEventListener('click', () => {
        fadeOut(cartModal);
        enableScroll();
    });

    modalInner && modalInner.addEventListener('click', function (event) {
        if (event.target === event.currentTarget) { // Проверяем, что клик произошел по самому modalInner
            fadeOut(cartModal);
            enableScroll();
        }
    });
}

function setupCartCounter(cartRow) {
    const counterBlock = cartRow.querySelector('.cart-table-counter');
    const input = counterBlock?.querySelector('input[name="amount"]');
    const select = counterBlock?.querySelector('select[name="currency"]');
    const weightElement = counterBlock?.querySelector('.cart-table-weight');

    // Отслеживаем изменения в input
    input && input.addEventListener('input', () => {
        counterBlock.classList.remove('is-error');
    });

    // Отслеживаем изменения в select
    select && select.addEventListener('change', () => {
        if (select.value === 'bag') {
            weightElement?.classList.add('active');
        } else {
            weightElement?.classList.remove('active');
        }
    });
}

function setupCartTypeSelect(cartRow) {
    const typeSelect = cartRow.querySelector('select[name="type"]');
    const selectBorder = cartRow.querySelector('.cart-table-select-border');

    typeSelect && typeSelect.addEventListener('change', () => {
        if (typeSelect.value === 'sample') {
            selectBorder?.classList.add('active');
        } else {
            selectBorder?.classList.remove('active');
        }
    });
}

function toggleNewAddressFields() {
    const deliveryAddressBlocks = document.querySelectorAll('.cart-delivery-method');

    deliveryAddressBlocks.forEach(deliveryAddressBlock => {
        const newAddressButton = deliveryAddressBlock.querySelector('.cart-delivery-new-address');
        const cancelButton = deliveryAddressBlock.querySelector('.cart-delivery-cancel');
        const newAddressField = deliveryAddressBlock.querySelector('.cart-delivery-field');
        const saveButton = deliveryAddressBlock.querySelector('.cart-delivery-save');

        newAddressButton?.addEventListener('click', () => {
            newAddressButton.style.display = "none";
            fadeIn(cancelButton);
            fadeIn(newAddressField);
            fadeIn(saveButton);
        });

        cancelButton?.addEventListener('click', () => {
            cancelButton.style.display = "none";
            fadeIn(newAddressButton);
            fadeOut(saveButton);
            fadeOut(newAddressField);
            newAddressField.value = "";
        });
    });
}

function toggleDeliveryMethod() {
    const deliveryAddressBlocks = document.querySelectorAll('.cart-delivery-method');

    deliveryAddressBlocks.forEach(deliveryAddressBlock => {
        const deliveryMethodSelect = deliveryAddressBlock.querySelector('select[name="deliveryMethod"]');
        const deliveryAddress = deliveryAddressBlock.querySelector('.cart-delivery-address');
        const deliveryPickup = deliveryAddressBlock.querySelector('.cart-delivery-pickup');

        deliveryMethodSelect?.addEventListener('change', () => {
            if (deliveryMethodSelect.value === 'pickup') {
                slideDown(deliveryPickup);
                slideUp(deliveryAddress);
            } else if (deliveryAddress.style.display == "none") {
                slideUp(deliveryPickup);
                slideDown(deliveryAddress);
            }
        });
    });
}

function checkForBagUnit(cartRow) {
    const hiddenUnit = cartRow.querySelector('.cart-hidden-unit');

    if (hiddenUnit && hiddenUnit.value === 'bag') {
        openModal("modalNote");
        return true;
    }
    return false;
}


