function setMaxWidthForElements() {
    let maxDateWidth = 0;
    let maxLabelWidth = 0;

    // Сброс ширины перед пересчетом
    const calendarDates = document.querySelectorAll('.calendar-date');
    const calendarLabels = document.querySelectorAll('.calendar-label');

    calendarDates.forEach(function (element) {
        element.style.width = ''; // Сбрасываем ширину перед пересчетом
    });

    calendarLabels.forEach(function (element) {
        element.style.width = ''; // Сбрасываем ширину перед пересчетом
    });

    // Определяем максимальную ширину для .calendar-date
    calendarDates.forEach(function (element) {
        const currentWidth = element.offsetWidth;
        if (currentWidth > maxDateWidth) {
            maxDateWidth = currentWidth;
        }
    });

    // Определяем максимальную ширину для .calendar-label
    calendarLabels.forEach(function (element) {
        const currentWidth = element.offsetWidth;
        if (currentWidth > maxLabelWidth) {
            maxLabelWidth = currentWidth;
        }
    });

    // Устанавливаем максимальные значения ширины для всех элементов
    calendarDates.forEach(function (element) {
        element.style.width = maxDateWidth + 'px';
    });

    calendarLabels.forEach(function (element) {
        element.style.width = maxLabelWidth + 'px';
    });
}

// Вызываем функцию при загрузке страницы
document.addEventListener('DOMContentLoaded', function () {
    setMaxWidthForElements();

    // Пересчитываем ширину при ресайзе окна
    window.addEventListener('resize', function () {
        setMaxWidthForElements();
    });

    // Создаем наблюдателя за изменениями в DOM
    const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            if (mutation.addedNodes.length) {
                setMaxWidthForElements(); // Пересчитываем ширину при добавлении новых элементов
            }
        });
    });

    const calendarContainer = document.querySelector('.calendar-list');
    if (calendarContainer) {
        observer.observe(calendarContainer, { childList: true, subtree: true });
    }
});

function setMaxHeightForTables() {
    let maxTableHeight = 0;

    // Сброс высоты перед пересчетом
    const tables = document.querySelectorAll('.updates-slider .updates-text table');

    tables.forEach(function (table) {
        table.style.height = ''; // Сбрасываем высоту перед пересчетом
    });

    // Определяем максимальную высоту таблицы
    tables.forEach(function (table) {
        const currentHeight = table.offsetHeight;
        if (currentHeight > maxTableHeight) {
            maxTableHeight = currentHeight;
        }
    });

    // Устанавливаем максимальную высоту для всех таблиц
    tables.forEach(function (table) {
        table.style.height = maxTableHeight + 'px';

        const tbodyHeight = table.querySelector("tbody").offsetHeight;
    });
}

// Вызываем функцию при загрузке страницы
document.addEventListener('DOMContentLoaded', function () {
    setMaxHeightForTables();

    // Пересчитываем высоту при ресайзе окна
    window.addEventListener('resize', function () {
        setMaxHeightForTables();
    });

    // Создаем наблюдателя за изменениями в DOM
    const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            if (mutation.addedNodes.length) {
                setMaxHeightForTables(); // Пересчитываем высоту при добавлении новых элементов
            }
        });
    });

    const sliderContainer = document.querySelector('.updates-slider');
    if (sliderContainer) {
        observer.observe(sliderContainer, { childList: true, subtree: true });
    }
});

new Swiper('.updates-slider', {
    slidesPerView: "auto",
    speed: 800,
    spaceBetween: 20,
    breakpoints: {
        1366: {
            spaceBetween: 0,
        }
    },
});

