document.addEventListener("DOMContentLoaded", function () {

	showHeaderForm();

	initSearchInput();

	initFilterAcccordions();

	toggleCountriesFilter();


	// Инициализация всех ползунков на странице
	document.querySelectorAll('.filter-slider').forEach(function (sliderElement) {
		initializeSlider(sliderElement);
	});

	filterReset();

	toggleMobFilter();

	togglePriceDetails(".price");

	TogglePurchase();

	initPurchaseAccordeon();
});

function showHeaderForm() {
	const page = document.querySelector(".page-price");
	if (!page) return;

	const header = document.querySelector(".header");
	const headerForm = document.querySelector(".header-form");

	setTimeout(() => {
		header.classList.add("anim");
		headerForm.classList.add("active");
	}, 2000);
}

function initFilterAcccordions() {
	const accordionItems = document.querySelectorAll('.filter-accordion');

	accordionItems.forEach(accordionItem => {
		const accordionHead = accordionItem.querySelector(".filter-accordion-header");
		const accordionContent = accordionItem.querySelector(".filter-accordion-content");

		accordionHead.addEventListener('click', function () {
			accordionHead.classList.toggle("active");
			slideToggle(accordionContent);
		});
	});
}

function toggleCountriesFilter() {
	const filter = document.querySelector(".filter-accordion--countries");
	const checkboxes = filter?.querySelectorAll(".filter-checkbox");
	const btn = filter?.querySelector(".filter-accordion-btn");

	btn && btn.addEventListener("click", () => {
		btn.classList.toggle("active");

		if (btn.classList.contains("active")) {
			checkboxes.forEach((checkbox, index) => {
				if (index >= 8) {
					slideDown(checkbox)
				}
			});
		} else {
			checkboxes.forEach((checkbox, index) => {
				if (index >= 8) {
					slideUp(checkbox);
				}
			});
		}

	});
}

function initializeSlider(sliderElement) {
	const slider = sliderElement.querySelector('.filter-slider-range');
	const inputMin = sliderElement.querySelector('.filter-input-min');
	const inputMax = sliderElement.querySelector('.filter-input-max');

	// Используем parseFloat для шага, чтобы поддерживать дробные значения
	const minValue = parseFloat(slider.getAttribute('data-min'));
	const maxValue = parseFloat(slider.getAttribute('data-max'));
	const stepValue = parseFloat(slider.getAttribute('data-step'));

	// Инициализация ползунка
	noUiSlider.create(slider, {
		start: [minValue, maxValue],
		connect: true,
		range: {
			'min': minValue,
			'max': maxValue
		},
		step: stepValue,
		tooltips: [false, false],
		format: {
			to: function (value) {
				return value.toFixed(stepValue % 1 === 0 ? 0 : 1); // Округляем в зависимости от значения шага
			},
			from: function (value) {
				return parseFloat(value);
			}
		}
	});

	// Обновление инпутов при изменении ползунка
	slider.noUiSlider.on('update', function (values, handle) {
		if (handle === 0) {
			inputMin.value = values[0];
		} else {
			inputMax.value = values[1];
		}
	});

	// Обновление ползунка при изменении инпутов
	inputMin.addEventListener('change', function () {
		const min = Math.max(minValue, Math.min(parseFloat(this.value), inputMax.value - stepValue));
		slider.noUiSlider.set([min, null]);
	});

	inputMax.addEventListener('change', function () {
		const max = Math.min(maxValue, Math.max(parseFloat(this.value), inputMin.value + stepValue));
		slider.noUiSlider.set([null, max]);
	});
}

function filterReset() {
	const filter = document.querySelector('.filter');

	filter && filter.addEventListener('reset', function () {
		setTimeout(() => {
			// Проходимся по всем ползункам
			document.querySelectorAll('.filter-slider').forEach(function (sliderElement) {
				const slider = sliderElement.querySelector('.filter-slider-range');
				const inputMin = sliderElement.querySelector('.filter-input-min');
				const inputMax = sliderElement.querySelector('.filter-input-max');

				// Получаем min и max из атрибутов инпутов
				const minValue = parseFloat(slider.getAttribute('data-min'));
				const maxValue = parseFloat(slider.getAttribute('data-max'));

				// Устанавливаем значения min и max в инпуты
				inputMin.value = minValue;
				inputMax.value = maxValue;

				// Обновляем значения в ползунке
				slider.noUiSlider.set([minValue, maxValue]);

				// Триггерим событие change, чтобы обновить ползунки
				const event = new Event('change');
				inputMin.dispatchEvent(event);
				inputMax.dispatchEvent(event);
			});
		}, 100);
	});
}

function toggleMobFilter() {
	const header = document.querySelector(".header");
	const headerToggleFilter = document.querySelector('.header-toggle-filter');
	const filter = document.querySelector('.filter');
	const filterHead = document.querySelector('.filter-head');

	headerToggleFilter && headerToggleFilter.addEventListener("click", () => {
		header.classList.remove("header-out");
		headerToggleFilter.classList.toggle("active");
		filter.classList.toggle("active");
		toggleScroll()
	});

	filterHead && filterHead.addEventListener("click", () => {
		headerToggleFilter.classList.remove("active");
		filter.classList.remove("active");
		enableScroll();
	});
}

function togglePriceDetails(cssSelector) {
	const table = document.querySelector(cssSelector);
	if (!table) return;

	const tableRows = table.querySelectorAll(".price-row");

	tableRows.forEach(tableRow => {
		const tableRowHead = tableRow.querySelector(".price-row-head");
		const tableRowCells = tableRow.querySelectorAll(".price-row-cell");
		const tableDetails = tableRow.querySelector(".price-details");

		tableRowHead.addEventListener("click", (event) => {
			if (event.target.closest(".price-btn-buy") || event.target.closest(".price-btn-pdf")) {
				return;
			}
			if (window.innerWidth < 1024 && event.target.closest(".hidden")) {
				return;
			}

			if (tableRow.classList.contains("active")) {
				tableRow.classList.remove("active");
				slideUp(tableDetails);

				if (window.innerWidth < 1024) {
					tableRowCells.forEach((tableRowCell) => {
						if (tableRowCell.classList.contains("hidden-mob")) {
							slideUp(tableRowCell);
						}
					})
				}
			} else {
				tableRow.classList.add("active");
				slideDown(tableDetails);

				if (window.innerWidth < 1024) {
					tableRowCells.forEach((tableRowCell) => {
						if (tableRowCell.classList.contains("hidden-mob")) {
							slideDown(tableRowCell, 500, 'grid');
						}
					})

					setTimeout(() => {
						scrollToPriceElement(tableRow);
					}, 500);
				}
			}
		})
	})

}

function initSearchInput() {
	const searchContainer = document.querySelector('.header-search');
	const searchInput = document.querySelector('.header-search-input input');

	if (!searchContainer && !searchInput) return;

	// Добавляем класс 'active' при фокусе на поле ввода
	searchInput.addEventListener('click', function () {
		if (window.innerWidth < 768) {
			searchContainer.classList.add('active');
		}
	});

	// Убираем класс 'active' при клике вне контейнера поиска
	document.addEventListener('click', function (event) {
		// Проверяем, кликнули ли вне контейнера с поиском
		if (!searchContainer.contains(event.target) && window.innerWidth < 768) {
			searchContainer.classList.remove('active');
		}
	});
}

function scrollToPriceElement(element, speed = 500) {
	const headerHeight = document.querySelector('.header').offsetHeight; // Получаем высоту хедера
	const elementOffsetTop = element.getBoundingClientRect().top + window.scrollY;
	const startingY = window.scrollY;
	let distance = elementOffsetTop - startingY;
	let startTime;

	const scrollDirection = distance >= 0 ? 'down' : 'up';
	// console.log(scrollDirection);
	// Учитываем высоту хедера при скролле вверх
	if (scrollDirection === 'up') {
		distance -= (headerHeight + 10);
	} else {
		distance -= 63;
	}

	function scrollAnimation(currentTime) {
		if (!startTime) {
			startTime = currentTime;
		}

		const progress = (currentTime - startTime) / speed;

		window.scrollTo({
			top: startingY + distance * Math.min(progress, 1),
			behavior: "auto"  // Отключаем встроенное поведение прокрутки
		});

		if (progress < 1) {
			requestAnimationFrame(scrollAnimation);
		}
	}

	requestAnimationFrame(scrollAnimation);
}

function TogglePurchase() {
	const btnsBuy = document.querySelectorAll(".price-btn-buy");
	const purchase = document.querySelector(".purchase");
	const purchaseClose = document.querySelector(".purchase-close");

	btnsBuy.forEach((btnBuy) => {
		btnBuy && btnBuy.addEventListener("click", () => {
			fadeIn(purchase);
			disableScroll();
		});
	})

	purchaseClose && purchaseClose.addEventListener("click", () => {
		fadeOut(purchase);
		enableScroll();
	});

	purchase && purchase.addEventListener('click', function (event) {
		if (event.target === event.currentTarget) { // Проверяем, что клик произошел по самому modal
			console.log("true");
			fadeOut(purchase);
			enableScroll();
		}
	});
}

function initPurchaseAccordeon() {
	const accordeonHead = document.querySelector(".purchase-accordeon-head");
	const accordeonList = document.querySelector(".purchase-list");
	const accordeonOverlay = document.querySelector(".purchase-overlay");

	accordeonHead && accordeonHead.addEventListener("click", function () {
		accordeonHead.classList.toggle("active");
		slideToggle(accordeonList);
		fadeToggle(accordeonOverlay);
	})

	accordeonOverlay && accordeonOverlay.addEventListener("click", function () {
		accordeonHead.classList.remove("active");
		slideUp(accordeonList);
		fadeOut(accordeonOverlay);
	})
}



