// =============================== caping start
// возможно есть смысл подключить в отдельном файле, только на страницах капинга!
document.addEventListener("DOMContentLoaded", () => {
	toggleCapingDetails(".table--short");

	toggleCapingDetails(".table--clone");

	validateModal('orderSampleModal');
	initTableOrderSample(".table--order-sample");

	validateModal('bookCoffeeModal');
	initTableBook(".table--book");

	initRating();
});


function toggleCapingDetails(cssSelector) {
	const table = document.querySelector(cssSelector);
	if (!table) return;

	const tableRows = table.querySelectorAll(".table-row");
	const tableBtnMore = table.querySelector(".table-btn-more");

	tableRows.forEach(tableRow => {
		const tableRowHead = tableRow.querySelector(".table-row-head");
		const tableRowCells = tableRow.querySelectorAll(".table-row-cell");
		const tableRowDetails = tableRow.querySelector(".table-details");

		tableRowHead.addEventListener("click", () => {
			if (tableRow.classList.contains("active")) {
				tableRow.classList.remove("active");
				slideUp(tableRowDetails);

				if (window.innerWidth < 1024) {
					tableRowCells.forEach((tableRowCell, index) => {
						if (index > 4 && !tableRowCell.classList.contains("table-row-price") && !tableRowCell.classList.contains("table-row-balance")) {
							slideUp(tableRowCell);
						}
					})
				}
			} else {
				tableRow.classList.add("active");
				slideDown(tableRowDetails);

				if (window.innerWidth < 1024) {
					tableRowCells.forEach((tableRowCell, index) => {
						if (index > 4 && !tableRowCell.classList.contains("table-row-price") && !tableRowCell.classList.contains("table-row-balance")) {
							slideDown(tableRowCell);
						}
					})

					setTimeout(() => {
						scrollToElement(tableRow);
					}, 500);
				}
			}
		})
	})

	if (!tableBtnMore) return;

	if (tableRows.length > 7) {
		tableBtnMore.addEventListener("click", () => {
			tableBtnMore.classList.toggle("active");

			tableRows.forEach((tableRow, index) => {
				if (index > 6) {
					slideToggle(tableRow);
				}
			})
		});
	} else {
		tableBtnMore.style.display = "none";
	}
}


function initTableOrderSample(cssSelector) {
	const table = document.querySelector(cssSelector);
	if (!table) return;

	toggleTableDetails(table);

	initializeCheckboxes(table);

	initializeSelects(table);

	toggleMobileTable(table);

	checkScrollPosition(table);
}

function initTableBook(cssSelector) {
	const table = document.querySelector(cssSelector);
	if (!table) return;

	toggleTableDetails(table);
	toggleMobileTable(table);
	checkScrollPosition(table);

	const simplebarVertical = document.querySelector(".simplebar-vertical");
	const tableRows = table.querySelectorAll(".table-row");
	const countDesktop = document.querySelector("#countDesktop");
	const countMobile = document.querySelector("#countMobile");
	const totalWeight = document.querySelector("#totalWeight");
	const totalPrice = document.querySelector("#totalPrice");

	tableRows.forEach(tableRow => {
		const checkboxElement = tableRow.querySelector('.table-checkbox input');
		const gradeButtonElement = tableRow.querySelector('.table-btn-grade');
		const submodalElement = tableRow.querySelector('.submodal');

		handleCheckboxChange(table, checkboxElement, countDesktop, countMobile, totalWeight, totalPrice, submodalElement);
		initModalHandlers(checkboxElement, gradeButtonElement, submodalElement, simplebarVertical);
		handleSubmodalSubmission(submodalElement, gradeButtonElement, checkboxElement, simplebarVertical);
		initCalculator(submodalElement);
	});
}

function toggleTableDetails(table) {
	const tableRows = table.querySelectorAll(".table-row");
	const tableContentWrapper = table.querySelector(".simplebar-content-wrapper");

	tableRows.forEach(tableRow => {
		const tableRowHead = tableRow.querySelector(".table-row-head");
		const tableRowCells = tableRow.querySelectorAll(".table-row-cell");
		const tableRowDetails = tableRow.querySelector(".table-details");

		tableRowHead.addEventListener("click", (event) => {
			if (isElementClicked(event, [".table-checkbox", ".table-select", ".table-btn-grade"])) return;

			if (tableRow.classList.contains("active")) {
				collapseRow(tableRow, tableRowDetails, tableRowCells);
			} else {
				expandRow(tableRow, tableRowDetails, tableRowCells, tableContentWrapper);
			}
		});
	});
}

function isElementClicked(event, selectors) {
	return selectors.some(function (selector) {
		return event.target.closest(selector);
	});
}

function collapseRow(tableRow, tableRowDetails, tableRowCells) {
	tableRow.classList.remove("active");
	slideUp(tableRowDetails);

	if (window.innerWidth < 1024) {
		tableRowCells.forEach((tableRowCell, index) => {
			if (tableRowCell.classList.contains("hidden")) {
				slideUp(tableRowCell);
			}
		});
	}
}

function expandRow(tableRow, tableRowDetails, tableRowCells, tableContentWrapper) {
	tableRow.classList.add("active");
	slideDown(tableRowDetails);

	if (window.innerWidth < 1024) {
		tableRowCells.forEach((tableRowCell, index) => {
			if (tableRowCell.classList.contains("hidden")) {
				slideDown(tableRowCell);
			}
		});

		setTimeout(() => {
			scrollIntoViewElement(tableRow, tableContentWrapper);
		}, 500);
	}
}

function toggleMobileTable(table) {
	const btnSave = document.getElementById("btnSave");
	const modalInner = document.querySelector(".modal-inner");
	const modalFields = document.querySelector(".modal-fields");
	const modalPositions = document.querySelector(".modal-positions");
	const modalFootOne = document.querySelector(".modal-foot-one");
	const modalFootTwo = document.querySelector(".modal-foot-two");

	btnSave.addEventListener("click", () => {
		modalInner.classList.add("is-end");
		table.style.display = "none";
		modalFootOne.style.display = "none";
		slideDown(modalFields);
		slideDown(modalPositions);
		slideDown(modalFootTwo);
	});

	modalPositions.addEventListener("click", () => {
		modalInner.classList.remove("is-end");
		modalPositions.style.display = "none";
		modalFields.style.display = "none";
		modalFootTwo.style.display = "none";
		table.style.display = "flex";
		// slideDown(table);
		slideDown(modalFootOne);
	});
}

function initializeCheckboxes(table) {
	const masterCheckbox = table.querySelector('#allSamples');
	const checkboxes = table.querySelectorAll('.table-row-checkbox input');
	const selectedCountElement = document.getElementById('countDesktop');

	// Функция для обновления количества выбранных чекбоксов
	function updateSelectedCount() {
		const selectedCount = table.querySelectorAll('.table-row-checkbox input:checked').length;
		selectedCountElement.textContent = selectedCount;
	}

	// Обработка нажатия на главный чекбокс
	masterCheckbox.addEventListener('change', function () {
		checkboxes.forEach(function (checkbox) {
			checkbox.checked = masterCheckbox.checked;
		});
		updateSelectedCount();
	});

	// Обработка нажатия на каждый индивидуальный чекбокс
	checkboxes.forEach(function (checkbox) {
		checkbox.addEventListener('change', function () {
			// Если все чекбоксы выбраны, устанавливаем masterCheckbox в checked
			const allChecked = table.querySelectorAll('.table-row-checkbox input:checked').length === checkboxes.length;
			masterCheckbox.checked = allChecked;

			updateSelectedCount();
		});
	});
}

function initializeSelects(table) {
	const masterSelect = document.querySelector('#coffeeConditionHead');
	const conditionSelects = table.querySelectorAll('select[name="coffeeCondition"]');

	// Обработка изменения главного селекта
	masterSelect.addEventListener('change', function () {
		const selectedValue = masterSelect.value;
		if (selectedValue !== "") {
			conditionSelects.forEach(function (select) {
				select.value = selectedValue;
			});
		}
	});
}

function handleCheckboxChange(table, checkboxElement, countDesktop, countMobile, totalWeight, totalPrice, submodalElement) {
	let activeCheckboxes;
	const btnSubmit = document.getElementById("btnSubmit");

	// Обновляем количество активных чекбоксов
	const updateSelectedCount = () => {
		const activeCount = activeCheckboxes.length;
		countDesktop.textContent = activeCount;
		countMobile.textContent = activeCount;
	};

	// Обновляем общий вес
	const updateTotalWeight = () => {
		let totalVol = 0;
		activeCheckboxes.forEach(checkbox => {
			// const submodal = checkbox.closest('.table-row').querySelector('.submodal');
			const volumeInput = submodalElement.querySelector('.submodal-calculator-field input[type="number"]');
			totalVol += parseFloat(volumeInput.value) || 0;
		});
		totalWeight.textContent = totalVol

		if (totalVol != 0) {
			btnSubmit.classList.add("is-book");
			return;
		}

		btnSubmit.classList.remove("is-book");
	};

	// Обновляем общую сумму
	const updateTotalPrice = () => {
		let totalAmt = 0;
		activeCheckboxes.forEach(checkbox => {
			// const submodal = checkbox.closest('.table-row').querySelector('.submodal');
			const sumInput = submodalElement.querySelector('.submodal-sum');
			totalAmt += parseFloat(sumInput.value) || 0;
		});
		totalPrice.textContent = totalAmt.toFixed(2); // Выводим сумму с двумя знаками после запятой
	};

	// Отслеживаем изменение чекбокса
	checkboxElement.addEventListener('change', () => {
		activeCheckboxes = table.querySelectorAll('.table-checkbox input:checked');
		// Фильтруем активные чекбоксы и обновляем данные
		updateSelectedCount();
		updateTotalWeight();
		updateTotalPrice();
	});
}

function initModalHandlers(checkboxElement, gradeButton, modal, simplebarVertical) {
	const closeButton = modal ? modal.querySelector('.submodal-close') : null;
	const ratingInputs = modal ? modal.querySelectorAll('.rating-input') : [];
	const inputField = modal ? modal.querySelector('input[type="number"]') : null;
	const totalSumElement = modal ? modal.querySelector('.submodal-calculator-sum') : null;
	const hiddenSumInput = modal ? modal.querySelector('input.submodal-sum') : null;

	const tableBody = modal.closest('.table-body');

	// Перемещаем modal сразу после .table-body
	tableBody.parentNode.insertBefore(modal, tableBody.nextSibling);

	if (checkboxElement && modal) {
		checkboxElement.addEventListener('click', function (event) {
			event.preventDefault();
			modal.classList.add('active');
			simplebarVertical.style.display = "none";
		});
	}

	if (gradeButton && modal) {
		gradeButton.addEventListener('click', function () {
			modal.classList.add('active');
			simplebarVertical.style.display = "none";
		});
	}

	if (closeButton && modal) {
		closeButton.addEventListener('click', function () {
			let shouldReset = false;

			// Проверка на наличие пустых полей
			ratingInputs.forEach(input => {
				if (input.value === "") {
					shouldReset = true;
				}
			});

			if (shouldReset) {
				inputField.value = 0; // Устанавливаем значение 0 для поля ввода количества
				totalSumElement.textContent = "0"; // Обнуляем сумму
				hiddenSumInput.value = "0"; // Обнуляем скрытое поле суммы
			}

			// Закрываем модальное окно
			modal.classList.remove('active');
			simplebarVertical.style.display = "";
		});
	}
}

function handleSubmodalSubmission(modal, gradeButton, checkbox, simplebarVertical) {
	const submitButton = modal ? modal.querySelector('.submodal-btn') : null;
	const message = modal ? modal.querySelector('.submodal-message') : null;
	const ratingInputs = modal ? modal.querySelectorAll('.rating-input') : [];
	const submodalGrades = modal ? modal.querySelectorAll('.submodal-grade') : [];
	const weightInput = modal ? modal.querySelector('.submodal-calculator-field input[type="number"]') : null;

	if (submitButton && ratingInputs.length === 2 && weightInput && checkbox) {
		// Обработка клика на кнопку submit
		submitButton.addEventListener('click', function () {
			let hasError = false;

			// Проверяем каждый input в rating
			ratingInputs.forEach((input, index) => {
				const gradeBlock = submodalGrades[index]; // Получаем родительский блок submodal-grade
				if (input.value === "") {
					gradeBlock.classList.add('is-error'); // Добавляем класс is-error
					hasError = true;
				} else {
					gradeBlock.classList.remove('is-error'); // Убираем класс is-error, если заполнено
				}
			});

			if (hasError) {
				message.style.display = 'block'; // Показываем сообщение
				submitButton.disabled = true; // Делаем кнопку неактивной
			} else {
				modal.classList.remove("active");
				gradeButton.classList.add("active");
				simplebarVertical.style.display = "";

				if (weightInput.value !== "" && weightInput.value !== 0 && weightInput.value !== "0") {
					// Активируем чекбокс и вызываем событие изменения
					checkbox.checked = true; // Ставим чекбокс в активное состояние
					checkbox.dispatchEvent(new Event('change')); // Вручную вызываем событие изменения
				} else {
					checkbox.checked = false; // Ставим чекбокс в неактивное состояние
					checkbox.dispatchEvent(new Event('change')); // Вручную вызываем событие изменения
				}
			}
		});

		// Отслеживаем изменение в input полях рейтинга
		ratingInputs.forEach(input => {
			input.addEventListener('input', function () {
				const gradeBlock = input.closest('.submodal-grade');
				gradeBlock.classList.remove('is-error'); // Убираем класс is-error при изменении

				// Проверяем, заполнены ли оба поля
				const allFilled = Array.from(ratingInputs).every(input => input.value !== "");
				if (allFilled) {
					message.style.display = 'none'; // Прячем сообщение
					submitButton.disabled = false; // Включаем кнопку
				}
			});
		});
	}
}

function initCalculator(modal) {
	const calculator = modal.querySelector('.submodal-calculator');
	const inputField = calculator.querySelector('input[type="number"]');
	const totalSumElement = calculator.querySelector('.submodal-calculator-sum');
	const hiddenSumInput = calculator.querySelector('input.submodal-sum');
	const price = parseFloat(calculator.getAttribute('data-price').replace(',', '.')); // Получаем цену из data-price

	if (inputField && totalSumElement && hiddenSumInput) {
		// Валидация и расчет при изменении значения в инпуте
		inputField.addEventListener('input', function () {
			let inputValue = parseInt(inputField.value);

			// Проверяем, чтобы вводились только целые числа в диапазоне
			if (isNaN(inputValue) || inputValue < inputField.min) {
				inputValue = inputField.min;
			} else if (inputValue > inputField.max) {
				inputValue = inputField.max;
			}

			inputField.value = inputValue; // Обновляем значение в поле после валидации

			// Рассчитываем итоговую сумму
			const totalSum = inputValue * price;

			// Обновляем сумму в интерфейсе
			totalSumElement.textContent = totalSum.toFixed(2);
			hiddenSumInput.value = totalSum.toFixed(2);
		});
	}
}

function scrollToElement(element, speed = 500) {
	const headerHeight = document.querySelector('.header').offsetHeight; // Получаем высоту хедера
	const elementOffsetTop = element.getBoundingClientRect().top + window.scrollY;
	const startingY = window.scrollY;
	let distance = elementOffsetTop - startingY;
	let startTime;

	const scrollDirection = distance >= 0 ? 'down' : 'up';
	// console.log(scrollDirection);
	// Учитываем высоту хедера при скролле вверх
	if (scrollDirection === 'up') {
		distance -= (headerHeight + 10);
	} else {
		distance -= 10;
	}

	function scrollAnimation(currentTime) {
		if (!startTime) {
			startTime = currentTime;
		}

		const progress = (currentTime - startTime) / speed;

		window.scrollTo({
			top: startingY + distance * Math.min(progress, 1),
			behavior: "auto"  // Отключаем встроенное поведение прокрутки
		});

		if (progress < 1) {
			requestAnimationFrame(scrollAnimation);
		}
	}

	requestAnimationFrame(scrollAnimation);
}

function scrollIntoViewElement(element, container, speed = 500) {
	const elementOffsetTop = element.offsetTop; // Позиция элемента относительно родителя (контейнера)
	const startingY = container.scrollTop; // Текущая позиция скролла контейнера
	let distance = elementOffsetTop - startingY; // Расстояние до элемента от текущей позиции прокрутки
	let startTime;

	function scrollAnimation(currentTime) {
		if (!startTime) {
			startTime = currentTime;
		}

		const progress = (currentTime - startTime) / speed;

		container.scrollTo({
			top: startingY + distance * Math.min(progress, 1),
			behavior: "auto"  // Отключаем встроенное поведение прокрутки
		});

		if (progress < 1) {
			requestAnimationFrame(scrollAnimation);
		}
	}

	requestAnimationFrame(scrollAnimation);
}

function initRating() {
	const ratings = document.querySelectorAll('.rating');

	if (ratings.length !== 0) {
		ratings.forEach(rating => {
			const input = rating.querySelector('.rating-input');
			const ratingWrapper = rating.querySelector('.rating-wrapper');
			const ratingItems = rating.querySelectorAll('.rating-item');

			// Обрабатываем каждый элемент рейтинга
			ratingItems.forEach(item => {
				item.addEventListener('click', function () {
					const value = this.getAttribute('data-rating-value');
					input.value = value;

					// Обновляем значение data-total-value в rating-wrapper
					ratingWrapper.setAttribute('data-total-value', value);

					// Вручную вызываем событие input, чтобы сработала валидация
					input.dispatchEvent(new Event('input'));
				});
			});
		});
	}
}

function validateModal(modalId) {
	const modal = document.getElementById(modalId);
	if (!modal) return;
	const requiredFields = modal.querySelectorAll('.required');
	const submitButton = modal.querySelector('.modal-btn');
	const form = modal.querySelector('form');

	// Функция проверки всех полей на заполненность
	function checkFields() {
		let allFilled = true;

		requiredFields.forEach((field) => {
			if (field.value.trim() === "") {
				field.classList.add('is-error');
				allFilled = false;
			} else {
				field.classList.remove('is-error');
			}
		});

		// Если все поля заполнены, убираем disabled с кнопки
		if (allFilled) {
			submitButton.disabled = false;
		} else {
			submitButton.disabled = true;
		}

		return allFilled;
	}

	// Проверка полей при отправке формы
	form.addEventListener('submit', function (event) {
		if (!checkFields()) {
			event.preventDefault(); // Останавливаем отправку формы, если есть ошибки
		}
	});

	// Убираем ошибку и проверяем поля при вводе данных
	requiredFields.forEach((field) => {
		field.addEventListener('input', checkFields);
	});
}

function checkScrollPosition(table) {
	const wrapper = table.querySelector('.simplebar-content-wrapper');
	if (!wrapper) return;

	let isScrolling = false;

	const updateScrollStatus = () => {
		const scrollHeight = wrapper.scrollHeight;
		const wrapperHeight = wrapper.clientHeight;
		const scrollTop = wrapper.scrollTop;

		// Проверка наличия скролла
		if (scrollHeight <= wrapperHeight) {
			wrapper.classList.add('no-scroll');
		} else {
			wrapper.classList.remove('no-scroll');
		}

		// Условие для верхней части
		if (scrollTop === 0) {
			wrapper.classList.add('is-start');
		} else {
			wrapper.classList.remove('is-start');
		}

		// Условие для нижней части
		if (scrollTop + wrapperHeight >= scrollHeight) {
			wrapper.classList.add('is-end');
		} else {
			wrapper.classList.remove('is-end');
		}
	};

	// Обработчик скролла с оптимизацией
	const handleScroll = () => {
		if (!isScrolling) {
			window.requestAnimationFrame(() => {
				updateScrollStatus();
				isScrolling = false;
			});
			isScrolling = true;
		}
	};

	updateScrollStatus();

	wrapper.addEventListener('scroll', handleScroll);
}



// =============================== caping end