function openModal(modalId) {
	const modal = document.getElementById(modalId);

	if (modal) {
		fadeIn(modal);
		disableScroll();
	}
}

function closeModal(modalId) {
	const modal = document.getElementById(modalId);

	if (modal) {
		fadeOut(modal);
		enableScroll();
	}
}


function closeModalOnClick() {
	// Закрытие по кнопке с классом 'modal-close'
	document.querySelectorAll('.modal-close').forEach(closeBtn => {
		closeBtn.addEventListener('click', function () {
			const modal = this.closest('.modal'); // Ищем родителя с классом modal
			if (modal) {
				fadeOut(modal);
				enableScroll();
			}
		});
	});

	// Закрытие по клику на сам элемент 'modal', но не его дочерние элементы
	document.querySelectorAll('.modal').forEach(modal => {
		modal.addEventListener('click', function (event) {
			if (event.target === event.currentTarget) { // Проверяем, что клик произошел по самому modal
				fadeOut(modal);
				enableScroll();
			}
		});
	});
}


closeModalOnClick();
