document.addEventListener('DOMContentLoaded', function () {
	gsap.registerPlugin(ScrollTrigger);

	animationForHomePage();
	animationForAboutUsPage();
	animationForContactsPage();


	function animationForHomePage() {
		const page = document.querySelector(".page-home");

		if (!page) return;

		// block "intro"
		gsap.timeline()
			.from(".intro-title", {
				autoAlpha: 0,
				duration: 2
			})
			.from(".intro-title", {
				y: 50,
				duration: 2.2
			}, "<");


		// block "about"
		gsap.from(".about-bottom", {
			y: 100,
			duration: 3,
			scrollTrigger: {
				trigger: ".about-bottom",
				start: "top bottom",
				toggleActions: "play none none none"
			}
		});

		// block "map"
		document.querySelectorAll(".map-svg").forEach((mapSvg) => {
			gsap.from(mapSvg, {
				opacity: 0,
				duration: 2,
				scrollTrigger: {
					trigger: mapSvg,
					start: "center bottom",
					toggleActions: "play none none none"
				}
			});
		});


		// block "parntership"
		gsap.timeline({
			scrollTrigger: {
				trigger: ".parntership-context",
				start: "top bottom",
				toggleActions: "play none none none"
			}
		})
			.from([".parntership-heading", ".parntership-text", ".parntership-picture"], {
				y: 50,
				duration: 2.2,
			})
			.from([".parntership-heading", ".parntership-text"], {
				opacity: 0,
				duration: 2,
			}, "<");

	}

	function animationForAboutUsPage() {
		const page = document.querySelector(".page-about-us");

		if (!page) return;

		if (window.innerWidth < 768) {
		} else {
			// intro animation pc
			const introTimeline = gsap.timeline({
				scrollTrigger: {
					trigger: ".intro-wrapper",
					start: "top 80px", // Анимация начинается, когда до верха экрана остается 80px
					end: "+=200%", // Анимация длится до 200% высоты триггера
					scrub: true,
					pin: true,
				}
			});

			// Анимация для intro-item, заканчивается на 30% прогресса
			introTimeline.to(".intro-item", {
				x: "0%",
				y: "0%",
				duration: 0.4, // Это 40% от общего времени анимации scrollTrigger
			});

			// Анимация изменения размера intro-grid, идет до конца scrollTrigger
			introTimeline.to(".intro-grid", {
				width: "100%",
				height: "100%",
				duration: 1
			}, 0); // Запуск этой анимации одновременно с предыдущей
		}


		// mission block
		gsap.from(".mission-inner", {
			y: 100,
			duration: 3,
			scrollTrigger: {
				trigger: ".mission-inner",
				start: "top bottom",
			},
			onComplete: function() {
				document.querySelector(".mission-inner").style.transform = "";
			}
		});


		// special block
		gsap.from(".special-wrapper", {
			y: 100,
			duration: 3,
			scrollTrigger: {
				trigger: ".special-wrapper",
				start: "top bottom",
				toggleActions: "play none none none"
			}
		});



		// control block 
		// Получаем полную высоту .control-grid
		const controlGrid = document.querySelector('.control-grid');
		const cards = document.querySelectorAll('.control-card');

		if (window.innerWidth < 768) {
			initScrollAnimation({
				gridSelector: '.control-grid',
				innerSelector: '.control-inner',
				itemSelector: '.control-card',
				heightMultiplier: 1
			});
		} else {
			// Функция для позиционирования карточек
			const positionCards = () => {
				// Находим самую высокую карточку
				let maxHeight = Math.max(...Array.from(cards).map(card => card.offsetHeight));

				// Устанавливаем всем карточкам одинаковую высоту
				cards.forEach(card => {
					card.style.height = `${maxHeight}px`;
				});

				// Располагаем карточки с равным смещением
				const containerWidth = controlGrid.offsetWidth;
				const cardWidth = cards[0].offsetWidth;
				const maxSpacingX = containerWidth - cardWidth; // Максимально возможное смещение
				const cardSpacingX = maxSpacingX / (cards.length - 1); // Равномерное распределение по X
				let cardSpacingY = 70; // Смещение вверх

				if (window.innerWidth < 1600) {
					cardSpacingY = 50;
				}

				cards.forEach((card, index) => {
					card.style.transform = `translate(${index * cardSpacingX}px, ${-index * cardSpacingY}px)`;
					card.style.zIndex = cards.length - index; // Обеспечиваем правильное наложение
				});

				// Устанавливаем высоту control-inner
				const controlGridHeight = maxHeight + (cardSpacingY * (cards.length - 1));
				controlGrid.style.height = `${controlGridHeight}px`;
			};

			// Оптимизация через debounce для события resize
			let resizeTimeout;
			const handleResize = () => {
				clearTimeout(resizeTimeout);
				resizeTimeout = setTimeout(() => {
					positionCards();
				}, 100); // Задержка в 100 миллисекунд
			};

			// Выполняем позиционирование при загрузке страницы
			positionCards();

			// Добавляем обработчик события resize
			window.addEventListener('resize', handleResize);
		}



		// team block 
		const teamSlider = document.querySelector('.team-two');
		const teamSliderScrollHeight = teamSlider.scrollHeight;

		if (window.innerWidth < 768) {
			initScrollAnimation({
				gridSelector: '.team-two',
				innerSelector: '.team-inner',
				itemSelector: '.team-wrapper',
				heightMultiplier: 1 // Передаем необходимый множитель
			});
		} else {
			const originalSlider = document.querySelector('.team-slider');
			const originalSliderWrapper = document.querySelector('.team-wrapper');
			const teamTwoContainer = document.querySelector('.team-two');
			const time = 15;

			originalSlider.appendChild(originalSliderWrapper.cloneNode(true));
			const cloneSlider = originalSlider.cloneNode(true)
			teamTwoContainer.appendChild(cloneSlider);
			originalSlider.classList.add("team-slider-one");
			cloneSlider.classList.add("team-slider-two");



			// Создаем анимацию бесконечной бегущей строки
			const marqueeAnimationOne = gsap.to('.team-slider-one .team-wrapper', {
				yPercent: -100,
				duration: time,
				ease: 'linear',
				repeat: -1,
				paused: false,
			});

			const marqueeAnimationTwo = gsap.from('.team-slider-two .team-wrapper', {
				yPercent: -100,
				duration: time,
				ease: 'linear',
				repeat: -1,
				paused: false,
			});

			function handleSliderHover(sliderElement, marquee) {
				sliderElement.addEventListener('mouseenter', () => {
					marquee.pause();
				});

				sliderElement.addEventListener('mouseleave', () => {
					marquee.play();
				});
			}

			handleSliderHover(originalSlider, marqueeAnimationOne);
			handleSliderHover(cloneSlider, marqueeAnimationTwo);
		}
	}

	function animationForContactsPage() {
		const page = document.querySelector(".page-contacts");

		if (!page) return;

		if (window.innerWidth <= 767) {
			initScrollAnimation({
				gridSelector: '.contacts-grid',
				innerSelector: '.contacts-two',
				itemSelector: '.team-two',
				heightMultiplier: 1 // Передаем необходимый множитель
			});
		} else {

		}
	}

	function initScrollAnimation(config) {
		const { gridSelector, innerSelector, itemSelector, heightMultiplier } = config;

		const gridElement = document.querySelector(gridSelector);
		if (!gridElement) return; // Проверяем, существует ли элемент

		const gridScrollHeight = gridElement.scrollHeight;
		const gridClientHeight = gridElement.clientHeight;

		// Анимация фиксации элемента с плавным easing
		gsap.to(innerSelector, {
			scrollTrigger: {
				trigger: innerSelector,
				start: "top 70px",
				end: `+=${gridScrollHeight * heightMultiplier}`,
				scrub: true,
				pin: true,
			}
		});

		// Анимация прокрутки для элементов внутри grid с плавным easing
		gsap.to(itemSelector, {
			y: -(gridScrollHeight - gridClientHeight),
			ease: "power2.inOut", // Плавный переход для прокрутки
			scrollTrigger: {
				trigger: innerSelector,
				start: "top 70px",
				end: `+=${gridScrollHeight * heightMultiplier}`,
				scrub: true,
			}
		});
	}

});

