if (window.innerWidth >= 1024) {
	possibilityPcAnimation()
} else {
	possibilityMobAnimation();
}

function possibilityPcAnimation() {
	const buttons = document.querySelectorAll('.possibility-btn');
	const mainImage = document.getElementById('mainImage');
	if (!mainImage) return;

	let autoSwitchInterval;
	let currentIndex = 0;
	const images = Array.from(buttons).map(button => button.getAttribute('data-image'));

	// Функция для смены изображения
	function changeImage(newImage, button) {
		if (!button.classList.contains('active')) {
			mainImage.classList.add('hidden'); // Добавляем класс для плавной смены

			setTimeout(() => {
				mainImage.src = newImage;
				mainImage.classList.remove('hidden'); // Убираем класс после смены изображения
			}, 200); // Должен совпадать с длительностью transition

			// Убираем класс 'active' со всех кнопок и добавляем его к текущей
			buttons.forEach(btn => btn.classList.remove('active'));
			button.classList.add('active');
		}
	}

	// Функция для автопереключения изображений
	function startAutoSwitch() {
		autoSwitchInterval = setInterval(() => {
			currentIndex = (currentIndex + 1) % images.length;
			changeImage(images[currentIndex], buttons[currentIndex]);
		}, 2000); // 2 секунды
	}

	// Останавливаем автопереключение при наведении мыши
	buttons.forEach((button, index) => {
		button.addEventListener('mouseover', () => {
			clearInterval(autoSwitchInterval);
			changeImage(button.getAttribute('data-image'), button);
			currentIndex = index; // Обновляем текущий индекс
		});

		button.addEventListener('mouseout', () => {
			startAutoSwitch(); // Включаем автопереключение после убирания мыши
		});
	});

	// Начинаем автопереключение при загрузке страницы
	startAutoSwitch();

}

function possibilityMobAnimation() {
	const buttons = document.querySelectorAll('.possibility-btn');
	const mainImage = document.getElementById('mainImage');
	if (!mainImage) return;
	
	let currentIndex = 0;
	let autoSwitchInterval;

	// Function to change the image
	function changeImage(newImage, button) {
		if (!button.classList.contains('active')) {
			mainImage.classList.add('hidden'); // Add class for smooth transition

			setTimeout(() => {
				mainImage.src = newImage;
				mainImage.classList.remove('hidden'); // Remove class after image change
			}, 200); // Should match the transition duration

			// Remove 'active' class from all buttons and add it to the current one
			buttons.forEach(btn => btn.classList.remove('active'));
			button.classList.add('active');
		}
	}

	// Function for automatic switching
	function autoSwitch() {
		currentIndex = (currentIndex + 1) % buttons.length;
		const nextButton = buttons[currentIndex];
		changeImage(nextButton.getAttribute('data-image'), nextButton);
	}

	// Start automatic switching
	function startAutoSwitch() {
		autoSwitchInterval = setInterval(autoSwitch, 2000);
	}

	// Stop automatic switching
	function stopAutoSwitch() {
		clearInterval(autoSwitchInterval);
	}

	// Start automatic switching initially
	startAutoSwitch();

	// Add 'click' event listener to each button
	buttons.forEach((button, index) => {
		button.addEventListener('click', () => {
			stopAutoSwitch(); // Stop automatic switching
			changeImage(button.getAttribute('data-image'), button);
			currentIndex = index; // Update currentIndex
			setTimeout(() => {
				startAutoSwitch(); // Resume automatic switching after 2 seconds
			}, 2000);
		});
	});
}


// Делегирование событий на родительский элемент .team-two
const teamElement = document.querySelector('.team-two');

teamElement && teamElement.addEventListener('click', (event) => {
	// Проверяем, произошел ли клик внутри карточки, и не произошел ли он на элементах, которые должны быть исключены
	const card = event.target.closest('.team-card');
	if (card && !event.target.closest('.team-info') && !event.target.closest('.team-back-head') && !event.target.closest('.team-back-body')) {
		card.classList.toggle('is-flipped');
	}
});

// history section
function duplicationSlides(sliderName) {
	// Ваш слайдер
	const slider = document.querySelector(sliderName);
	if (!slider) return;

	const sliderWpapper = slider.querySelector(".swiper-wrapper");
	const slides = slider.querySelectorAll(".swiper-slide");

	// Дублирование слайдов
	slides.forEach((slide) => {
		const clone = slide.cloneNode(true);
		sliderWpapper.appendChild(clone);
	});
}

// duplicationSlides('.history-slider-block');
// duplicationSlides('.history-subslider');

document.addEventListener("DOMContentLoaded", () => {
	// Инициализация верхнего слайдера с привязкой к нижнему через thumbs
	const teamSwiperTop = new Swiper('.history-slider-block', {
		// loop: true,
		slidesPerView: "auto",
		spaceBetween: 10,
		freeMode: true,
		navigation: {
			nextEl: '.history-slider .btn-next',
			prevEl: '.history-slider .btn-prev',
		},
		watchSlidesProgress: true,
		breakpoints: {
			768: {
				spaceBetween: 5,
				slidesPerView: 15,
				freeMode: false,
			},
			1024: {
				spaceBetween: 10,
				slidesPerView: 15,
				freeMode: false,
			},
		},
	});

	// Инициализация нижнего слайдера .history-subslider (Thumbs)
	const teamSwiperBottom = new Swiper('.history-subslider', {
		// loop: true,
		slidesPerView: "auto",
		spaceBetween: 23,
		centeredSlides: false,
		thumbs: {
			swiper: teamSwiperTop
		},
		slideToClickedSlide: true,
		breakpoints: {
			768: {
				spaceBetween: 30,
				centeredSlides: true,
			},
			1024: {
				spaceBetween: 63,
				centeredSlides: true,
			},
			1600: {
				spaceBetween: 84,
				centeredSlides: true,
			},
		},
	});
});



// gallery section
document.addEventListener('DOMContentLoaded', function () {
	// Инициализация Swiper
	new Swiper('.gallery-slider', {
		loop: true,
		slidesPerView: "auto",
		speed: 800,
		centeredSlides: true,
		navigation: {
			nextEl: '.gallery-nav .btn-next',
			prevEl: '.gallery-nav .btn-prev',
		},
	});

	// Инициализация LightGallery для всех элементов с классом gallery-image
	lightGallery(document.querySelector('.gallery-slider'), {
		selector: '.gallery-image',
		plugins: [lgZoom, lgThumbnail],
		licenseKey: '67B29887-3DD24218-A6DD4DB4-E8DAB8FA',
		thumbnail: true,
		zoom: true,
	});

	document.querySelectorAll('.gallery-open').forEach(function (button, index) {
		button.addEventListener('click', function () {
			// Ищем соответствующее изображение для открытия
			const slide = button.closest('.swiper-slide');
			const image = slide.querySelector('.gallery-image');
			if (image) {
				image.click();
			}
		});
	});
});











