// Назначаем обработчик клика для каждой панели при загрузке страницы
document.addEventListener('DOMContentLoaded', function () {
	togglePanel();


	const panelAccordions = document.querySelectorAll('.panel-auth-accordion');
	const panelRecovery = document.querySelector('.panel-auth-recovery');

	panelAccordions.forEach(panelAccordion => {
		const panelHeader = panelAccordion.querySelector('.panel-auth-header');

		panelHeader.addEventListener('click', function () {
			if (!panelAccordion.classList.contains("active")) {
				openSpecificPanel(panelAccordion.id, panelAccordions, panelRecovery);
			}
		});
	});

	openPanelRecovery(panelRecovery, panelAccordions);
});

function togglePanel() {
	const headerLink = document.querySelector(".header-link--cabinet");
	const panel = document.querySelector('.panel');

	headerLink && headerLink.addEventListener("click", () => {
		fadeIn(panel);
		toggleScroll();
	});

	panel && panel.addEventListener("click", (event) => {
		if (!event.target.closest(".panel-auth") && !event.target.closest(".panel-profile")) {
			fadeOut(panel);
			enableScroll();
		}
	});
}

function openSpecificPanel(panelId, panelAccordions, panelRecovery) {
	// Закрываем все панели перед открытием выбранной
	panelAccordions.forEach(panel => {
		const content = panel.querySelector('.panel-auth-content');
		const header = panel.querySelector('.panel-auth-header');
		if (panel.id !== panelId) {
			// fadeOut(content);
			// content.style.display = "none";
			// content.classList.remove('active');
			slideUp(content);
			panel.classList.remove('active');
			header.classList.remove('active');
		} else {
			// slideDown(content, 1500);
			// content.style.display = "block";
			// content.classList.add('active');
			slideDown(content);
			panel.classList.add('active');
			header.classList.add('active');
		}
	});
	
	// Закрываем поле recovery
	if(panelRecovery.style.display != "none") {
		slideUp(panelRecovery);
		panelRecovery.classList.remove('active');
	}
}

function closeAllPanels(panelAccordions) {
	panelAccordions.forEach(panel => {
		const content = panel.querySelector('.panel-auth-content');
		const header = panel.querySelector('.panel-auth-header');

		slideUp(content);
		panel.classList.remove('active');
		header.classList.remove('active');
	});
}

function openPanelRecovery(panelRecovery, panelAccordions) {
	const btn = document.querySelector(".panel-auth-btn");

	btn && btn.addEventListener("click", function() {
		closeAllPanels(panelAccordions);
		slideDown(panelRecovery);
		panelRecovery.classList.add('active');
	});
}