document.addEventListener("DOMContentLoaded", function () {
    const video = document.querySelector('.video-tag');
    const playButton = document.querySelector('.video-play-btn');
    const muteButton = document.querySelector('.video-mute-btn');
    const fullscreenButton = document.querySelector('.video-fullscreen-btn');
    const wrapper = document.querySelector('.video-wrapper');
    let isPlaying = false;
    let isSourceSet = false;

    if(!video) return;

    // Воспроизведение / Пауза при нажатии на видео или кнопку play
    const source = video.querySelector('source');
    const togglePlay = () => {
        if (!isSourceSet) {
            source.setAttribute('src', video.getAttribute('data-src'));
            video.load(); // Загрузить видео после установки источника
            isSourceSet = true; // Установить флаг, чтобы больше не менять источник
        }
        if (!isPlaying) {
            video.play();
        } else {
            video.pause();
        }
        isPlaying = !isPlaying;
        wrapper.classList.toggle('plays');
    };

    // Переключение звука
    const toggleMute = () => {
        video.muted = !video.muted;
        muteButton.classList.toggle('active');
    };

    // Полноэкранный режим
    const toggleFullscreen = () => {
        if(wrapper.classList.contains('is-full')) {
            wrapper.classList.remove('is-full');
            enableScroll();
        } else {
            wrapper.classList.add('is-full');
            disableScroll();
        }
        
    };

    // События
    playButton.addEventListener('click', togglePlay);
    video.addEventListener('click', togglePlay);
    muteButton.addEventListener('click', toggleMute);
    fullscreenButton.addEventListener('click', toggleFullscreen);
});
