new Swiper('.map-list', {
	slidesPerView: 'auto',
	spaceBetween: 10,
	speed: 800,
	freeMode: true,
	breakpoints: {
		768: {
			spaceBetween: 0,
		}
	}
});

initMapAccordions();

function initMapAccordions() {
	const accordions = document.querySelectorAll('.map-accordeon');
	let lastClickTime = 0; // Переменная для хранения времени последнего клика
	const speed = 800;

	accordions.forEach(function (accordion) {
		accordion.addEventListener('click', function () {
			if (accordion.classList.contains("active")) return;

			const currentTime = new Date().getTime(); // Текущее время в миллисекундах
			// Если с последнего клика прошло меньше 500 миллисекунд, прекращаем выполнение
			if (currentTime - lastClickTime < speed) return;
			lastClickTime = currentTime;

			const oldAccordeonElement = document.querySelector(".map-accordeon.active");
			const oldWrapperElement = document.querySelector(".map-wrapper.active");
			const oldHeadLinkElement = document.querySelector(".map-head-link.active");
			const oldMapCountryElement = document.querySelector(".map-country.active");
			const oldMapBodyLinkElement = document.querySelector(".map-body-link.active");
			const oldCountryNameElement = document.querySelector(".map-country-name.active");
			const oldPathElement = document.querySelector(".map-svg path.active");
			const oldMapLabelElement = document.querySelector(".map-label.selected");

			oldAccordeonElement.classList.remove("active");
			slideUp(oldAccordeonElement.querySelector(".map-body"), speed);
			oldWrapperElement.classList.remove("active");
			oldWrapperElement.style.display = "none";

			if (oldHeadLinkElement && oldMapCountryElement && oldMapBodyLinkElement && oldCountryNameElement && oldPathElement && oldMapLabelElement) {
				oldHeadLinkElement.classList.remove("active");
				oldMapCountryElement.classList.remove("active");
				oldMapBodyLinkElement.classList.remove("active");
				oldCountryNameElement.classList.remove("active");
				oldPathElement.classList.remove("active");
				oldPathElement.removeAttribute("transform");
				oldMapLabelElement.classList.remove("selected");
			}

			// Добавляем класс active для текущего аккордеона и показываем его контент
			accordion.classList.add('active');
			slideDown(accordion.querySelector('.map-body'), speed, "block");

			const newGalleryElement = document.getElementById(accordion.getAttribute('data-map'));
			newGalleryElement.classList.add('active');
			if (window.innerWidth >= 768) {
				fadeIn(newGalleryElement, speed);
			}
		});
	});
}

// Найти все map-item элементы
const mapItems = document.querySelectorAll(".map-item");

mapItems.forEach(mapItem => {
	// Найти внутри каждого map-item все элементы с классом map-country
	const mapCountries = mapItem.querySelectorAll(".map-country");
	const mapWrapper = document.getElementById(mapItem.querySelector(".map-accordeon").getAttribute('data-map'));
	const mapHeadLink = mapItem.querySelector(".map-head-link");
	const svg = mapWrapper.querySelector(".map-svg");

	// Функция для переключения активного состояния
	function toggleActiveState(mapCountry, pathElement, textElement) {
		const countryName = mapCountry.querySelector(".map-country-name");
		const countryLink = mapCountry.querySelector(".map-body-link");

		// Если уже есть класс active, удаляем его, сбрасываем transform
		if (mapCountry.classList.contains("active")) {
			mapHeadLink.classList.remove("active");
			mapCountry.classList.remove("active");
			countryName.classList.remove("active");
			countryLink.classList.remove("active");
			pathElement.classList.remove("active");
			pathElement.removeAttribute("transform");
			textElement.classList.remove("selected");
		} else {
			// Добавляем класс active
			mapHeadLink.classList.add("active");
			mapCountry.classList.add("active");
			countryName.classList.add("active");
			countryLink.classList.add("active");
			pathElement.classList.add("active");
			textElement.classList.add("selected");

			// Найдем границы (bounding box) элемента для вычисления центра
			const bbox = pathElement.getBBox();
			const width = bbox.width;
			const height = bbox.height;

			// Рассчитаем центр элемента
			const centerX = bbox.x + width / 2;
			const centerY = bbox.y + height / 2;

			// Устанавливаем масштаб в зависимости от ширины элемента
			let scale = 1; // Масштаб по умолчанию

			if (window.innerWidth > 768) {
				scale = 2.5;
				if (width < 50) {
					scale = 5;
				} else if (width > 150) {
					scale = 1.5;
				}
			} else {
				scale = 2;
				if (width < 50) {
					scale = 5;
				} else if (width > 50 && width < 120) {
					scale = 1.8;
				} else if (width > 120 && width < 200) {
					scale = 2.2;
				} else if (width > 200) {
					scale = 1.5;
				}
			}

			console.log(scale);

			// Применим transform с масштабом и коррекцией перемещения
			pathElement.setAttribute(
				"transform",
				`translate(${centerX}, ${centerY}) scale(${scale}) translate(${-centerX}, ${-centerY})`
			);

			// Переместить pathElement в конец SVG, чтобы оно отображалось поверх остальных элементов
			svg.appendChild(pathElement);

			// Обновляем позицию элемента
			textElement.setAttribute("x", centerX);
			textElement.setAttribute("y", centerY);
			svg.appendChild(textElement);
		}

		// Удаляем классы active у всех других элементов
		const oldCountries = mapItem.querySelectorAll(".map-country.active");
		const oldTextElements = svg.querySelectorAll(".map-label");

		oldCountries.forEach(oldCountry => {
			if (oldCountry && oldCountry !== mapCountry) {
				const oldCountryPath = document.getElementById(`${oldCountry.dataset.id}`);
				oldCountry.classList.remove("active");
				oldCountry.querySelector(".map-country-name").classList.remove("active");
				oldCountry.querySelector(".map-body-link").classList.remove("active");
				oldCountryPath.classList.remove("active");
				oldCountryPath.removeAttribute("transform");
			}
		});

		oldTextElements.forEach(oldTextElement => {
			if (oldTextElement && oldTextElement !== textElement) {
				oldTextElement.classList.remove("selected");
			}
		});
	}

	// Для каждого элемента map-country добавляем класс selected
	mapCountries.forEach(mapCountry => {
		const countryName = mapCountry.querySelector(".map-country-name");
		const pathElement = document.getElementById(`${mapCountry.dataset.id}`);
		pathElement.classList.add("selected");

		let textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");
		textElement.classList.add("map-label");
		textElement.setAttribute("text-anchor", "middle");
		textElement.setAttribute("dominant-baseline", "middle");
		textElement.textContent = countryName.textContent;

		// Обработчик клика по элементу countryName
		countryName.addEventListener("click", () => toggleActiveState(mapCountry, pathElement, textElement));

		// Обработка клика по pathElement
		pathElement.addEventListener('click', () => toggleActiveState(mapCountry, pathElement, textElement));

		if (window.matchMedia('(hover: hover)').matches) {
			// Обработка наведения
			pathElement.addEventListener('mouseenter', () => {
				if (!pathElement.classList.contains("active")) {
					// Найдем границы (bounding box) элемента для вычисления центра
					const bbox = pathElement.getBBox();
					const width = bbox.width;
					const height = bbox.height;

					// Рассчитаем центр элемента
					const centerX = bbox.x + width / 2;
					const centerY = bbox.y + height / 2;
					textElement.setAttribute("x", centerX);
					textElement.setAttribute("y", centerY);
					svg.appendChild(textElement);
					textElement.classList.add("active");
				}
			});

			// Обработка ухода
			pathElement.addEventListener('mouseleave', () => {
				textElement.classList.remove("active");
			});
		}
	});

	// Обработка клика по mapHeadLink
	mapHeadLink.addEventListener("click", function () {
		const activeLink = mapItem.querySelector(".map-body-link.active");

		if (activeLink) {
			const href = activeLink.getAttribute("href");
			const target = activeLink.getAttribute("target");

			// Если ссылка есть
			if (href) {
				// Проверяем, есть ли target="_blank"
				if (target === "_blank") {
					// Открываем ссылку в новой вкладке
					window.open(href, "_blank");
				} else {
					// Переходим по ссылке в том же окне
					window.location.href = href;
				}
			}
		}
	});
});



function setCountryId() {
	// Массив стран
	const northAmericaCountries = {
		wdasjs2: "United Arab Emirates",
		wdasjs3: "Afghanistan",
		wdasjs4: "American Samoa",
		wdasjs5: "Bangladesh",
		wdasjs6: "Bahrain",
		wdasjs7: "Brunei Darussalam",
		wdasjs8: "Bhutan",
		wdasjs9: "Cocos (Keeling) Islands",
		wdasjs10: "China",
		wdasjs11: "Christmas Island",
		wdasjs12: "Federated States of Micronesia",
		wdasjs13: "Guam",
		wdasjs14: "Hong Kong",
		wdasjs15: "Indonesia",
		wdasjs16: "Israel",
		wdasjs17: "India",
		wdasjs18: "British Indian Ocean Territory",
		wdasjs19: "Iraq",
		wdasjs20: "Iran",
		wdasjs21: "Jordan",
		wdasjs22: "Japan",
		wdasjs23: "Kyrgyzstan",
		wdasjs24: "Cambodia",
		wdasjs25: "North Korea",
		wdasjs26: "South Korea",
		wdasjs27: "Kuwait",
		wdasjs28: "Kazakhstan",
		wdasjs29: "Laos",
		wdasjs30: "Lebanon",
		wdasjs31: "Sri Lanka",
		wdasjs32: "Marshall Islands",
		wdasjs33: "Macau",
		wdasjs34: "Myanmar",
		wdasjs35: "Mongolia",
		wdasjs36: "Northern Mariana Islands",
		wdasjs37: "Mauritius",
		wdasjs38: "Maldives",
		wdasjs39: "Malaysia",
		wdasjs40: "Nepal",
		wdasjs41: "Nauru",
		wdasjs42: "Niue",
		wdasjs43: "Oman",
		wdasjs44: "Papua New Guinea",
		wdasjs45: "Philippines",
		wdasjs46: "Pakistan",
		wdasjs47: "Palau",
		wdasjs48: "Palestinian Territories",
		wdasjs49: "Qatar",
		wdasjs50: "Saudi Arabia",
		wdasjs51: "Solomon Islands",
		wdasjs52: "Seychelles",
		wdasjs53: "Singapore",
		wdasjs54: "Syria",
		wdasjs55: "Thailand",
		wdasjs56: "Tajikistan",
		wdasjs57: "Tokelau",
		wdasjs58: "Timor-Leste",
		wdasjs59: "Turkmenistan",
		wdasjs60: "Tuvalu",
		wdasjs61: "Taiwan",
		wdasjs62: "Baker Island",
		wdasjs63: "Howland Island",
		wdasjs64: "Johnston Atoll",
		wdasjs65: "Midway Islands",
		wdasjs66: "Wake Island",
		wdasjs67: "Uzbekistan",
		wdasjs68: "Vietnam",
		wdasjs69: "Vanuatu",
		wdasjs70: "Wallis and Futuna",
		wdasjs71: "Samoa",
		wdasjs72: "Yemen"
	};

	// Получаем все path элементы внутри .currentMap
	const paths = document.querySelectorAll('.Map path');

	// Преобразуем ключи массива в массив
	const countryIds = Object.keys(northAmericaCountries);

	// Проходим по path и устанавливаем id из массива по порядку
	paths.forEach((path, index) => {
		if (countryIds[index]) {
			path.id = countryIds[index];
		}
	});
}


const northAmericaCountries = {
	wdnajs1: "Antigua and Barbuda",
	wdnajs2: "Anguilla",
	wdnajs3: "Aruba",
	wdnajs4: "Barbados",
	wdnajs5: "Saint Barthelemy",
	wdnajs6: "Bermuda",
	wdnajs7: "Bonaire, Saint Eustatius and Saba",
	wdnajs8: "Bahamas",
	wdnajs9: "Belize",
	wdnajs10: "Canada",
	wdnajs11: "Costa Rica",
	wdnajs12: "Cuba",
	wdnajs13: "Curaçao",
	wdnajs14: "Dominica",
	wdnajs15: "Dominican Republic",
	wdnajs16: "Grenada",
	wdnajs17: "Greenland",
	wdnajs18: "Guadeloupe",
	wdnajs19: "Guatemala",
	wdnajs20: "Honduras",
	wdnajs21: "Haiti",
	wdnajs22: "Jamaica",
	wdnajs23: "Saint Kitts and Nevis",
	wdnajs24: "Cayman Islands",
	wdnajs25: "Saint Lucia",
	wdnajs26: "Saint Martin",
	wdnajs27: "Martinique",
	wdnajs28: "Montserrat",
	wdnajs29: "Mexico",
	wdnajs30: "Nicaragua",
	wdnajs31: "Panama",
	wdnajs32: "Saint Pierre and Miquelon",
	wdnajs33: "Puerto Rico",
	wdnajs34: "El Salvador",
	wdnajs35: "Turks and Caicos Islands",
	wdnajs36: "Trinidad and Tobago",
	wdnajs37: "United States",
	wdnajs38: "Saint Vincent and the Grenadines",
	wdnajs39: "British Virgin Islands",
	wdnajs40: "US Virgin Islands"
};

const southAmericaCountries = {
	wdsajs1: "Argentina",
	wdsajs2: "Bolivia",
	wdsajs3: "Brazil",
	wdsajs4: "Bouvet Island",
	wdsajs5: "Chile",
	wdsajs6: "Colombia",
	wdsajs7: "Ecuador",
	wdsajs8: "Falkland Islands",
	wdsajs9: "French Guiana",
	wdsajs10: "South Georgia and the South Sandwich Islands",
	wdsajs11: "Guyana",
	wdsajs12: "Peru",
	wdsajs13: "Paraguay",
	wdsajs14: "Suriname",
	wdsajs15: "Uruguay",
	wdsajs16: "Venezuela"
};

const africaCountries = {
	wdafjs1: "Angola",
	wdafjs2: "Burkina Faso",
	wdafjs3: "Burundi",
	wdafjs4: "Benin",
	wdafjs5: "Botswana",
	wdafjs6: "Democratic Republic of Congo",
	wdafjs7: "Central African Republic",
	wdafjs8: "Republic of Congo",
	wdafjs9: "Ivory Coast",
	wdafjs10: "Cameroon",
	wdafjs11: "Cape Verde",
	wdafjs12: "Djibouti",
	wdafjs13: "Algeria",
	wdafjs14: "Egypt",
	wdafjs15: "Western Sahara",
	wdafjs16: "Eritrea",
	wdafjs17: "Canary Islands",
	wdafjs18: "Ethiopia",
	wdafjs19: "Gabon",
	wdafjs20: "Ghana",
	wdafjs21: "Gambia",
	wdafjs22: "Guinea",
	wdafjs23: "Equatorial Guinea",
	wdafjs24: "Guinea-Bissau",
	wdafjs25: "Juan De Nova Island",
	wdafjs26: "Kenya",
	wdafjs27: "Comoros",
	wdafjs28: "Liberia",
	wdafjs29: "Lesotho",
	wdafjs30: "Libya",
	wdafjs31: "Morocco",
	wdafjs32: "Madagascar",
	wdafjs33: "Mali",
	wdafjs34: "Mauritania",
	wdafjs35: "Mauritius",
	wdafjs36: "Malawi",
	wdafjs37: "Mozambique",
	wdafjs38: "Namibia",
	wdafjs39: "Niger",
	wdafjs40: "Nigeria",
	wdafjs41: "Madeira",
	wdafjs42: "Reunion",
	wdafjs43: "Rwanda",
	wdafjs44: "Seychelles",
	wdafjs45: "Sudan",
	wdafjs46: "Saint Helena",
	wdafjs47: "Sierra Leone",
	wdafjs48: "Senegal",
	wdafjs49: "Somalia",
	wdafjs50: "South Sudan",
	wdafjs51: "Sao Tome and Principe",
	wdafjs52: "Swaziland",
	wdafjs53: "Chad",
	wdafjs54: "Togo",
	wdafjs55: "Tunisia",
	wdafjs56: "Tanzania",
	wdafjs57: "Uganda",
	wdafjs58: "Mayotte",
	wdafjs59: "South Africa",
	wdafjs60: "Zambia",
	wdafjs61: "Zimbabwe"
};

const asiaCountries = {
	wdasjs1: "Russia", // удалена из карты
	wdasjs2: "United Arab Emirates",
	wdasjs3: "Afghanistan",
	wdasjs4: "American Samoa",
	wdasjs5: "Bangladesh",
	wdasjs6: "Bahrain",
	wdasjs7: "Brunei Darussalam",
	wdasjs8: "Bhutan",
	wdasjs9: "Cocos (Keeling) Islands",
	wdasjs10: "China",
	wdasjs11: "Christmas Island",
	wdasjs12: "Federated States of Micronesia",
	wdasjs13: "Guam",
	wdasjs14: "Hong Kong",
	wdasjs15: "Indonesia",
	wdasjs16: "Israel",
	wdasjs17: "India",
	wdasjs18: "British Indian Ocean Territory",
	wdasjs19: "Iraq",
	wdasjs20: "Iran",
	wdasjs21: "Jordan",
	wdasjs22: "Japan",
	wdasjs23: "Kyrgyzstan",
	wdasjs24: "Cambodia",
	wdasjs25: "North Korea",
	wdasjs26: "South Korea",
	wdasjs27: "Kuwait",
	wdasjs28: "Kazakhstan",
	wdasjs29: "Laos",
	wdasjs30: "Lebanon",
	wdasjs31: "Sri Lanka",
	wdasjs32: "Marshall Islands",
	wdasjs33: "Macau",
	wdasjs34: "Myanmar",
	wdasjs35: "Mongolia",
	wdasjs36: "Northern Mariana Islands",
	wdasjs37: "Mauritius",
	wdasjs38: "Maldives",
	wdasjs39: "Malaysia",
	wdasjs40: "Nepal",
	wdasjs41: "Nauru",
	wdasjs42: "Niue",
	wdasjs43: "Oman",
	wdasjs44: "Papua New Guinea",
	wdasjs45: "Philippines",
	wdasjs46: "Pakistan",
	wdasjs47: "Palau",
	wdasjs48: "Palestinian Territories",
	wdasjs49: "Qatar",
	wdasjs50: "Saudi Arabia",
	wdasjs51: "Solomon Islands",
	wdasjs52: "Seychelles",
	wdasjs53: "Singapore",
	wdasjs54: "Syria",
	wdasjs55: "Thailand",
	wdasjs56: "Tajikistan",
	wdasjs57: "Tokelau",
	wdasjs58: "Timor-Leste",
	wdasjs59: "Turkmenistan",
	wdasjs60: "Tuvalu",
	wdasjs61: "Taiwan",
	wdasjs62: "Baker Island",
	wdasjs63: "Howland Island",
	wdasjs64: "Johnston Atoll",
	wdasjs65: "Midway Islands",
	wdasjs66: "Wake Island",
	wdasjs67: "Uzbekistan",
	wdasjs68: "Vietnam",
	wdasjs69: "Vanuatu",
	wdasjs70: "Wallis and Futuna",
	wdasjs71: "Samoa",
	wdasjs72: "Yemen"
};


