new Swiper('.category-slider', {
	slidesPerView: 'auto',
	spaceBetween: 10,
	speed: 800,
	freeMode: true,
	breakpoints: {
		544: {
			slidesPerView: 5,
		},
		768: {
			spaceBetween: 0,
			slidesPerView: 'auto',
		}
	}
});




initDoingsliders();


function initDoingsliders() {
	const doingsCarousel = document.querySelectorAll(".doings-carousel");

	doingsCarousel.forEach(eventCarousel => {
		const slider = eventCarousel.querySelector(".doings-slider");
		const btnPrev = eventCarousel.querySelector(".btn-prev");
		const btnNext = eventCarousel.querySelector(".btn-next");
		const doingsName = eventCarousel.querySelectorAll(".doings-name");

		new Swiper(slider, {
			slidesPerView: 'auto',
			spaceBetween: 10,
			speed: 800,
			loop: true,
			navigation: {
				nextEl: btnNext,
				prevEl: btnPrev,
			},
			breakpoints: {
				1366: {
					spaceBetween: 20,
					slidesPerView: 4,
				},
				1600: {
					spaceBetween: 24,
					slidesPerView: 4,
				}
			},
			on: {
				init: function () {
					alignMaxHeightHeight(doingsName);
				},
				resize: function () {
					alignMaxHeightHeight(doingsName);
				},
			},
		});
	})
}

function alignMaxHeightHeight(list) {
	let maxHeight = 0;

	list.forEach(function (item) {
		item.style.height = ''; // Сбросить инлайн-стиль
		let itemHeight = item.offsetHeight;
		maxHeight = Math.max(maxHeight, itemHeight);
	});

	list.forEach(function (item) {
		item.style.height = maxHeight + 'px';
	});
}


initCabinetAccordions();

function initCabinetAccordions() {
	const accordions = document.querySelectorAll('.cabinet-accordeon');
	let lastClickTime = 0; // Переменная для хранения времени последнего клика
	const speed = 800;

	accordions.forEach(function (accordion) {
		accordion.addEventListener('click', function () {
			if (accordion.classList.contains("active")) return;

			const currentTime = new Date().getTime(); // Текущее время в миллисекундах
			// Если с последнего клика прошло меньше 500 миллисекунд, прекращаем выполнение
			if (currentTime - lastClickTime < speed) return;
			lastClickTime = currentTime;

			const oldAccordeonElement = document.querySelector(".cabinet-accordeon.active");
			const oldGalleryElement = document.querySelector(".cabinet-gallery.active");
			oldAccordeonElement.classList.remove("active");
			slideUp(oldAccordeonElement.querySelector(".cabinet-accordeon-body"), speed);
			oldGalleryElement.classList.remove("active");
			oldGalleryElement.style.display = "none";

			// Добавляем класс active для текущего аккордеона и показываем его контент
			accordion.classList.add('active');
			slideDown(accordion.querySelector('.cabinet-accordeon-body'), speed, "block");

			// Находим элемент по data-gallery и показываем его
			const newGalleryElement = document.getElementById(accordion.getAttribute('data-gallery'));
			newGalleryElement.classList.add('active');
			if (window.innerWidth >= 768) {
				fadeIn(newGalleryElement, speed);
			}
		});
	});
}

initTabs();
function initTabs() {
  var tabLinks = document.querySelectorAll('.tab-link');
  var tabContents = document.querySelectorAll('.tab-content');
  tabLinks.forEach(function (link) {
    link.addEventListener('click', function () {
      if (link.classList.contains("active")) return;
      var targetTab = link.getAttribute('data-tab');
      var currentTab = link;
      var currentContent = document.getElementById(targetTab);
      // Удаляем активные классы со всех табов 
      tabLinks.forEach(function (link) {
        link.classList.remove('active');
      });
      tabContents.forEach(function (content) {
        content.style.display = "none";
      });
      // Добавляем активный класс на выбранный таб и его контент
      currentTab.classList.add('active');
      fadeIn(currentContent, 800);
    });
  });
}



