const shopCard = document.getElementById("shopCard");
let swiperInstance = null;
let lightGalleryInstance = null;

function openShopCard() {
    fadeIn(shopCard);
    disableScroll();

    // Инициализация Swiper при открытии
    swiperInstance = new Swiper('.shop-slider', {
        spaceBetween: 10,
        loop: true,
        slidesPerView: "auto",
        speed: 800,
        navigation: {
            nextEl: '.shop-carousel-btn--next',
            prevEl: '.shop-carousel-btn--prev',
        },
        breakpoints: {
            768: {
                spaceBetween: 20,
                slidesPerView: 1,
            }
        },
    });

    // Инициализация LightGallery при открытии
    lightGalleryInstance = lightGallery(document.querySelector('.shop-slider'), {
        selector: '.shop-slide',
        plugins: [lgZoom, lgThumbnail],
        licenseKey: '67B29887-3DD24218-A6DD4DB4-E8DAB8FA',
        thumbnail: true,
        zoom: true,
    });
}

function closeShopCard() {
    fadeOut(shopCard);
    enableScroll();

    setTimeout(() => {
        // Уничтожение Swiper при закрытии
        if (swiperInstance) {
            swiperInstance.destroy(true, true);
            swiperInstance = null;
        }

        // Уничтожение LightGallery при закрытии
        if (lightGalleryInstance) {
            lightGalleryInstance.destroy();
            lightGalleryInstance = null;
        }
    }, 500);
}


togglePriceAccordeon();

function togglePriceAccordeon() {
    const accordeonTitle = document.querySelector(".shop-character-title");
    const accordeonList = document.querySelector(".shop-character-list");

    accordeonTitle && accordeonTitle.addEventListener("click", () => {
        if (window.innerWidth < 768) {
            accordeonTitle.classList.toggle("hide");
            slideToggle(accordeonList);
        }
    });
}
