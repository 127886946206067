document.addEventListener('DOMContentLoaded', function () {
	// Ищем все элементы с классом lightgallery
	document.querySelectorAll('.lightgallery').forEach(function (galleryElement) {
		const openGalleryBtn = galleryElement.querySelector('.lightgallery-open');
		const galleryWrapper = galleryElement.querySelector('.lightgallery-wrapper');

		// Проверяем наличие класса lightgallery-thumbnail для включения миниатюр
		const hasThumbnailClass = galleryElement.classList.contains('lightgallery-thumbnail');

		// Инициализация LightGallery для каждого элемента
		const lightGalleryInstance = lightGallery(galleryWrapper, {
			plugins: [lgZoom, lgThumbnail, lgVideo],
			licenseKey: '67B29887-3DD24218-A6DD4DB4-E8DAB8FA',
			thumbnail: hasThumbnailClass,  // Включаем миниатюры, если класс присутствует
		});

		openGalleryBtn.addEventListener('click', function () {
			// Открытие первой картинки в конкретной галерее
			lightGalleryInstance.openGallery(0);
		});
	});
});
