function setEqualHeight() {
	const wrapper = document.querySelector('.contacts-one-wrapper');
	const form = document.querySelector('.contacts-form');

	if (wrapper && form) {
		// Удаляем установленную высоту перед пересчетом
		wrapper.style.height = 'auto';
		form.style.height = 'auto';

		if (window.innerWidth < 768) return;

		// Получаем новую высоту обоих блоков
		const wrapperHeight = wrapper.offsetHeight;
		const formHeight = form.offsetHeight;

		// Находим большее значение
		const maxHeight = Math.max(wrapperHeight, formHeight);

		// Устанавливаем одинаковую высоту для обоих блоков
		wrapper.style.height = maxHeight + 'px';
		form.style.height = maxHeight + 'px';
	}
}

// Вызываем функцию при загрузке страницы и при изменении размера окна
setEqualHeight();
window.addEventListener('resize', setEqualHeight);
