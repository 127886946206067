document.addEventListener('DOMContentLoaded', function () {
	textSpoiler('.about-toggle', '.about-text');
	textSpoiler('.special-toggle', '.special-text');
	textSpoiler('.paper-content .btn-text-toggle', '.paper-content .text-editor');
	textSpoiler('.article .btn-text-toggle', '.article .article-text');

	checkForm();

	togglePopupPDF();
});

function textSpoiler(toggleClass, textClass) {
	const toggleButtons = document.querySelectorAll(toggleClass);
	const textBlocks = document.querySelectorAll(textClass);

	toggleButtons.forEach((toggleButton, index) => {
		const textBlock = textBlocks[index];

		if (toggleButton && textBlock) {
			const maxHeight = window.getComputedStyle(textBlock).maxHeight;

			if (textBlock.scrollHeight <= textBlock.offsetHeight || maxHeight === 'none') {
				toggleButton.style.display = 'none'; // Скрываем кнопку
			} else {
				// Добавляем событие клика на кнопку
				toggleButton.addEventListener('click', function () {
					if (!toggleButton.classList.contains('active')) {
						textBlock.style.maxHeight = textBlock.scrollHeight + 'px';
					} else {
						textBlock.style.maxHeight = '';
					}

					toggleButton.classList.toggle('active');
				});
			}
		}
	});
}

function checkForm() {
	const forms = document.querySelectorAll('form');

	forms && forms.forEach(form => {
		const inputs = form.querySelectorAll('input.required');
		const captcha = form.querySelector('.form-captcha');
		let isFormComplete = false;

		function checkFormFields() {
			let allFilled = true;

			inputs.forEach(input => {
				if (input.value.trim() === '') {
					allFilled = false;
				}
			});

			// Если все поля заполнены и класс еще не был добавлен
			if (allFilled && !isFormComplete && captcha) {
				fadeIn(captcha);
				isFormComplete = true;
			}
		}

		// Отслеживаем события изменения в полях
		inputs && inputs.forEach(input => {
			input.addEventListener('input', checkFormFields);
		});
	});
}

function togglePopupPDF() {
	const btns = document.querySelectorAll(".btn-pdf");
	const pdfPopup = document.querySelector(".pdf-popup");
	const pdfFrame = document.querySelector(".pdf-popup-iframe");
	const pdfClose = document.querySelector(".pdf-popup-close");
	btns.forEach(function (item) {
		const targetSrc = item.getAttribute('data-src');
		item.addEventListener("click", function () {
			const isMobile = isMobileOrTablet();

			if (isMobile) {
				alert();
				// Открытие ссылки в новой вкладке для мобильных устройств и планшетов
				window.open(targetSrc, '_blank');
			} else {
				// Текущая логика для ПК
				fadeIn(pdfPopup);
				pdfFrame.src = targetSrc;
				disableScroll();
			}
		})
	})
	pdfPopup && pdfPopup.addEventListener("click", function (event) {
		if (event.target == this) {
			closePdfPopup();
		}
	})
	pdfClose && pdfClose.addEventListener("click", function (event) {
		closePdfPopup();
	})
	function closePdfPopup() {
		fadeOut(pdfPopup);
		enableScroll();
	}

	function isMobileOrTablet() {
		const userAgent = navigator.userAgent.toLowerCase();
		return /mobile|android|iphone|ipad|tablet/i.test(userAgent);
	}
}

function togglePasswordVisibility() {
	const passwordContainers = document.querySelectorAll('.form-password');

	passwordContainers.forEach(container => {
		const passwordInput = container.querySelector('.form-input');
		const toggleButton = container.querySelector('.form-password-btn');

		toggleButton.addEventListener('click', () => {
			toggleButton.classList.toggle("active");
			passwordInput.type = passwordInput.type === 'text' ? 'password' : 'text';
		});
	});
}

// Вызываем функцию для инициализации
togglePasswordVisibility();
