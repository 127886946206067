// header

headerHide();
toggleHeader();
initHeaderLang();

function headerHide() {
	const header = document.querySelector('.header');
	let scrollPrev = 0;
	let isTicking = false;

	window.addEventListener('scroll', function () {
		const scrolled = window.pageYOffset;

		if (document.body.classList.contains("disable-scroll")) return;

		// Используем requestAnimationFrame для оптимизации
		if (!isTicking) {
			window.requestAnimationFrame(function () {
				// Логика скрытия/показа header
				if (scrolled > 20 && scrolled > scrollPrev) {
					header.classList.add('header-out');
				} else {
					header.classList.remove('header-out');
				}

				// Сохраняем текущее значение прокрутки
				scrollPrev = scrolled;
				// Ставим флаг о том, что работа закончена
				isTicking = false;

				// Логика для добавления/удаления класса 'active'
				if (scrolled > 0 && !header.classList.contains('active')) {
					header.classList.add('active');
				} else if (scrolled === 0 && header.classList.contains('active')) {
					header.classList.remove('active');
				}
			});

			// Ставим флаг, что анимация сейчас активна
			isTicking = true;
		}
	});
}


function initHeaderLang() {
	const headerLangElements = document.querySelectorAll('.header-lang');

	headerLangElements.forEach(headerLang => {
		const headerLangActive = headerLang.querySelector('.header-lang-active');

		if (headerLangActive) {
			if (window.matchMedia('(hover: hover)').matches) {
				headerLangActive.addEventListener('mouseenter', function () {
					headerLang.classList.add('active');
				});

				headerLang.addEventListener('mouseleave', function () {
					headerLang.classList.remove('active');
				});
			} else {
				headerLangActive.addEventListener('click', function () {
					headerLang.classList.toggle('active');
				});
			}

		}
	});
}

function toggleHeader() {
	const headerBurger = document.querySelector(".header-burger");
	const headerMenu = document.querySelector(".header-menu");
	const headerClose = document.querySelector(".header-close");

	if (headerBurger && headerMenu && headerClose) {
		headerBurger.addEventListener('click', function () {
			fadeIn(headerMenu);
			disableScroll();
		});
		headerClose.addEventListener('click', function () {
			fadeOut(headerMenu);
			enableScroll();
		});

		headerMenu.addEventListener('click', function (event) {
			if (event.target === this) {
				fadeOut(headerMenu);
				enableScroll();
			}
		});
	}
}

// ================ header

