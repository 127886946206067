function initQuantityCalculators() {
    const calculators = document.querySelectorAll('.quantity-calculator');

    calculators.forEach(calculator => {
        const minusBtn = calculator.querySelector('.quantity-btn--minus');
        const plusBtn = calculator.querySelector('.quantity-btn--plus');
        const input = calculator.querySelector('.quantity-input');

        // Ограничение ввода только целыми числами
        input.addEventListener('input', () => {
            input.value = input.value.replace(/[^0-9]/g, ''); // Удаляет все, кроме цифр
        });

        // Проверка минимального значения при потере фокуса
        input.addEventListener('blur', () => {
            if (input.value === '' || parseInt(input.value, 10) < 1) {
                input.value = 1;
            }
        });

        minusBtn.addEventListener('click', () => {
            let currentValue = parseInt(input.value, 10) || 1; // Используем 1, если значение пустое или NaN
            if (currentValue > 1) {
                input.value = currentValue - 1;
            }
        });

        plusBtn.addEventListener('click', () => {
            let currentValue = parseInt(input.value, 10) || 0; // Используем 0, если значение пустое или NaN
            input.value = currentValue + 1;
        });
    });
}

// Инициализация всех компонентов на странице
initQuantityCalculators();
